import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, map, Observable, tap } from 'rxjs';

import { environment } from '@environments/environment';
import { EquipmentGroup } from '@core/interfaces/equipment-group.interface';
import { IStaff } from '@core/interfaces/staff.interface';
import { IAlarm, IUpdateAlarm } from '@core/interfaces/alarm.interface';
import { DatadogService } from '@core/services/datadog.service';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  // RENAME THIS SERVICE
  datadogService = inject(DatadogService);
  httpClient = inject(HttpClient);
  externalStaffReference: string = '';

  getAlarms(): Observable<IAlarm[]> {
    const url = environment.chargingInfo + 'alarmSettings/getAlarmSettings';
    return this.httpClient.get<IAlarm[]>(url).pipe(
      tap((alarms) => this.datadogService.logAlarms(alarms)),
      catchError((err) => {
        throw err;
      }),
    );
  }

  postAlarm(alarmData: any): Observable<any> {
    const url = environment.chargingInfo + 'alarmSettings/postAlarmSettings';
    return this.httpClient.post<IUpdateAlarm[]>(url, alarmData).pipe(
      catchError((err) => {
        throw err;
      }),
    );
  }

  deleteAllAlarms() {
    const url = environment.chargingInfo + 'alarmSettings/clearCustomerAlarmSettings';
    return this.httpClient.delete<any[]>(url).pipe(
      catchError((err) => {
        throw err;
      }),
    );
  }

  deleteAlarm(ruleId: string) {
    const url = environment.chargingInfo + 'alarmSettings/deleteAlarmSettings' + '?ruleId=' + ruleId;
    return this.httpClient.delete<any[]>(url).pipe(
      catchError((err) => {
        throw err;
      }),
    );
  }

  getStaffList() {
    const url = environment.admin + 'v1/staff';
    return this.httpClient.get<IStaff[]>(url).pipe(
      map((staff) => {
        return staff.map((staffItem) => {
          return { ...staffItem, name: staffItem.firstName + ' ' + staffItem.lastName };
        });
      }),
      catchError((err) => {
        throw err;
      }),
    );
  }

  getGroupList() {
    const url = environment.admin + 'v1/equipment-groups';
    return this.httpClient.get<EquipmentGroup[]>(url).pipe(
      catchError((err) => {
        throw err;
      }),
    );
  }

  getStaffByReference(staffRef: string) {
    const url = environment.admin + 'v1/staff/' + staffRef;
    return this.httpClient.get<IStaff>(url).pipe(
      catchError((err) => {
        throw err;
      }),
    );
  }
}
