import { Injectable, computed, signal } from '@angular/core';
import { UserPreferenceVehicleIDStringKey } from '@core/interfaces/staff.interface';

export type VehicleID = 'chassisNumber' | 'registrationNumber' | 'alias';

@Injectable({
  providedIn: 'root',
})
export class UserPreferencesService {
  usersPreferredVehicleIdentifier = signal<UserPreferenceVehicleIDStringKey>('Vehicle alias');

  vehicleID = computed<VehicleID>(() => {
    if (this.usersPreferredVehicleIdentifier() === 'Chassi number') return 'chassisNumber';
    if (this.usersPreferredVehicleIdentifier() === 'Registration number') return 'registrationNumber';
    if (this.usersPreferredVehicleIdentifier() === 'Vehicle alias') return 'alias';
    return 'alias';
  });
}
