export interface IUserPermission {
  externalUserPermissionReference: string;
  bitPosition: number;
  name: string;
  description: string;
}

export const userPermissionsMap: Array<IUserPermission> = [
  {
    externalUserPermissionReference: '6c1476b8-597a-4430-8b81-65e0f8f9f587',
    name: 'ReadPositioning',
    description: 'Read Positioning',
    bitPosition: 1,
  },
  {
    externalUserPermissionReference: 'eae0317a-bafa-4221-98bb-7d102082a024',
    name: 'ReadTrackingVehicle',
    description: 'Read tracking vehicle',
    bitPosition: 2,
  },
  {
    externalUserPermissionReference: '3aa568b1-7164-41e9-8232-e6760eee9f3f',
    name: 'CreateEditDeleteGeofenceZone',
    description: 'Create/Edit/Delete Geofence zones for alrms and',
    bitPosition: 3,
  },
  {
    externalUserPermissionReference: '51ded638-524d-4d1b-8c23-233be288316d',
    name: 'ReadGeofenceAlarms',
    description: 'Read geofence alarms',
    bitPosition: 4,
  },
  {
    externalUserPermissionReference: 'bccae0c2-47f6-44e9-9608-fe7776c5f310',
    name: 'HandleGeofenceAlarms',
    description: 'Handle geofence alarms',
    bitPosition: 5,
  },
  {
    externalUserPermissionReference: 'a70d90ed-9fee-458f-9532-e62ee084bde5',
    name: 'ReadGeofenceNotifications',
    description: 'Read geofence notifications',
    bitPosition: 6,
  },
  {
    externalUserPermissionReference: '7a5a37fd-42dd-47f6-ab30-1442e429a479',
    name: 'DriveTimeSettings',
    description: 'Drivetime settings',
    bitPosition: 7,
  },
  {
    externalUserPermissionReference: '3f69e919-d18a-4010-a08a-0380ee28dc37',
    name: 'ReadDriveTimeAllData',
    description: 'Read all drivetime',
    bitPosition: 8,
  },
  {
    externalUserPermissionReference: '778b26f5-01ef-4ec8-a63d-a6aafdc11db5',
    name: 'ReadDriveTimeOwnData',
    description: 'Read own drivetime',
    bitPosition: 9,
  },
  {
    externalUserPermissionReference: '8f0074a5-b683-45e7-868c-1c604062491a',
    name: 'ReadVehicleExceptions',
    description: 'Read vehicle exceptions',
    bitPosition: 10,
  },
  {
    externalUserPermissionReference: '43dc9f8b-8c0e-47ef-9260-05e2ba9d8b02',
    name: 'EnvironmentalReportingSettings',
    description: 'Environmental reporting settings',
    bitPosition: 11,
  },
  {
    externalUserPermissionReference: '6cc6b63f-9ed0-4858-9b5f-62ddf87dacc1',
    name: 'ReadEnvironmentalReporting',
    description: 'Read environmental reporting',
    bitPosition: 12,
  },
  {
    externalUserPermissionReference: 'cdee979e-ce67-494b-8b29-55fbba04c449',
    name: 'EditTrafficLightSettings',
    description: 'Edit traffic light settings',
    bitPosition: 13,
  },
  {
    externalUserPermissionReference: '07865dc6-4cc4-4026-9c5a-9810c42051ad',
    name: 'ReadDriverPerformanceOwnDataAnonymizedValues',
    description: 'Read driver performance, anonymized values, own data',
    bitPosition: 14,
  },
  {
    externalUserPermissionReference: 'f3dfa2b1-cf14-4d44-aedc-bc2095d036b3',
    name: 'ReadDriverPerformanceIdentifiedData',
    description: 'Read driver performance, identified data',
    bitPosition: 15,
  },
  {
    externalUserPermissionReference: 'dcddd70e-94f9-4941-9c6d-4451e9930987',
    name: 'ReadVehiclePerformanceOwnDataAnonymizedValues',
    description: 'Read vehicle performance, anonymized values, own data',
    bitPosition: 16,
  },
  {
    externalUserPermissionReference: 'b6b0d677-c87a-4a40-8344-360fcfacb364',
    name: 'ReadVehiclePerformanceIdentifiedData',
    description: 'Read Vehicle Performance, identified data',
    bitPosition: 17,
  },
  {
    externalUserPermissionReference: '0cacaf43-3e02-42c9-9bfd-df9b2e8513f0',
    name: 'ServicePlanningSettings',
    description: 'Service Planning Settings',
    bitPosition: 18,
  },
  {
    externalUserPermissionReference: 'b5ca26e2-f13c-4014-9b50-e594fa0d801d',
    name: 'CreateEditDeleteServicePlanning',
    description: 'Create/Edit/Delete service planning',
    bitPosition: 19,
  },
  {
    externalUserPermissionReference: 'edb932ab-7395-4146-bb0a-aa0c055c6e9c',
    name: 'DefectReporting',
    description: 'Defect reporting',
    bitPosition: 20,
  },
  {
    externalUserPermissionReference: '3101380c-46c8-4e7f-a031-5c92bc20abc4',
    name: 'ReadServicePlanning',
    description: 'Read service planning',
    bitPosition: 21,
  },
  {
    externalUserPermissionReference: '1adea39f-67f2-4f8f-a4f1-ebae340ac748',
    name: 'GeneralCompanySettings',
    description: 'General company settings',
    bitPosition: 22,
  },
  {
    externalUserPermissionReference: '0446d3c1-b455-484f-b96b-bea9f9b4f3c8',
    name: 'CreateEditDeleteEquipmentEditVehicle',
    description: 'Create/Edit/Delete Equipment and Edit Vehicle',
    bitPosition: 23,
  },
  {
    externalUserPermissionReference: '8a140bff-a387-4528-af70-43095777ab5e',
    name: 'EditOwnContactInformation',
    description: 'Edit Staff - own user',
    bitPosition: 24,
  },
  {
    externalUserPermissionReference: 'f5caae5f-8e07-45e7-9396-54412b85d8f1',
    name: 'CreateEditDeleteStaffAllRolesButSuperUser',
    description: 'Create/Edit/Delete Staff - all roles but super user',
    bitPosition: 25,
  },
  {
    externalUserPermissionReference: '0616a25b-dd7d-4700-a35f-bf21c95bf103',
    name: 'CreateEditDeleteStaffAllRoles',
    description: 'Create/Edit/Delete Staff - all role',
    bitPosition: 26,
  },
  {
    externalUserPermissionReference: '22218900-1df8-40b4-b7f7-ab97e8c20643',
    name: 'CreateEditReadDeleteDepot',
    description: 'Create/Edit/Read/Delete Depot',
    bitPosition: 27,
  },
  {
    externalUserPermissionReference: 'e9acaceb-ef4b-4302-ae3c-2ea89ef4eeb1',
    name: 'GeneralFunctionality',
    description: 'General functionality',
    bitPosition: 28,
  },
  {
    externalUserPermissionReference: '9fd685dd-d3e8-425d-b95a-48fed5a2f8c0',
    name: 'Impersonate',
    description: 'Allows impersonation af any user in any company',
    bitPosition: 29,
  },
  {
    externalUserPermissionReference: '531a83bf-6680-46fa-a1e5-1660a14531aa',
    name: 'Messaging',
    description: 'Messaging',
    bitPosition: 30,
  },
  {
    externalUserPermissionReference: '97a62288-f57c-4a27-96f5-109dc4514d12',
    name: 'FuelReport',
    description: 'Fuel report',
    bitPosition: 31,
  },
  {
    externalUserPermissionReference: 'b640170e-c26a-4684-8fc6-8a56f37ac519',
    name: 'ReadDriverCoachingDriverReport',
    description: 'Can access the driver report under Coaching',
    bitPosition: 32,
  },
  {
    externalUserPermissionReference: '70132f6c-5fbd-467f-b7b4-9a51bddfb9e1',
    name: 'ReadDriverCoachingManagementReport',
    description: 'Can access the management report under Coaching',
    bitPosition: 33,
  },
  {
    externalUserPermissionReference: '59d8d07c-642c-44dd-aea4-41c48df37ce2',
    name: 'CreateEditDeleteAddressBookEntries',
    description: 'Can create, update and delete entries in address book',
    bitPosition: 34,
  },
  {
    externalUserPermissionReference: '6eb865d5-4b02-4095-aa3f-de409c9d3e54',
    name: 'ReadDriverPerformanceAllData',
    description: 'Can read all driver performance data for company (CWP pages)',
    bitPosition: 35,
  },
  {
    externalUserPermissionReference: '3ea0525e-ed51-4ad4-b979-26019b27236e',
    name: 'ReadDriverPerformanceOwnData',
    description: 'Can read own driver evaluation pages (app)',
    bitPosition: 36,
  },
  {
    externalUserPermissionReference: '74433e75-f6c3-4d85-b152-19fb91ed290e',
    name: 'ManageReportRecipients',
    description: 'Create/Read/Update report recipients',
    bitPosition: 37,
  },
  {
    externalUserPermissionReference: '7bbb8c4b-6298-467c-bdf3-174cf3589eec',
    name: 'CreateEditDeleteStaff',
    description: 'Create/Read/Update staff',
    bitPosition: 38,
  },
  {
    externalUserPermissionReference: '08ad58f2-f5ea-4722-89d2-185d35cc02ca',
    name: 'MessagingAllowed',
    description: 'Messaging allowed',
    bitPosition: 39,
  },
  {
    externalUserPermissionReference: 'cb77b27d-a8ca-4e09-a59d-744821d875ba',
    name: 'AddressBookAllowed',
    description: 'Address book allowed',
    bitPosition: 40,
  },
  {
    externalUserPermissionReference: '9c226a37-b369-41a7-8f78-4565288b4245',
    name: 'ReportsAllowed',
    description: 'Reports allowed',
    bitPosition: 41,
  },
  {
    externalUserPermissionReference: '31d2d6c0-ded9-4f5a-a803-9ea850be7f58',
    name: 'EditDefect',
    description: 'Edit defect',
    bitPosition: 42,
  },
  {
    externalUserPermissionReference: '588faffe-8703-4794-aed3-7d6ca9f59657',
    name: 'BuyInScaniaOne',
    description: 'Buy in Scania One',
    bitPosition: 43,
  },
  {
    externalUserPermissionReference: '4d261a53-1200-4fd8-b151-c7776b9912ca',
    name: 'ReadMonitoringReport',
    description: 'ReadMonitoringReport',
    bitPosition: 44,
  },
  {
    externalUserPermissionReference: 'ac02e879-0e72-4f80-bda5-ac5d8eb1ed01',
    name: 'ReadScaniaAgreementMaterial',
    description: 'ReadScaniaAgreementMaterial',
    bitPosition: 45,
  },
  {
    externalUserPermissionReference: '2aa679ad-efa9-4d93-8e4a-c35988d681fc',
    name: 'ReadOwnPositioning',
    description: 'ReadOwnPositioning',
    bitPosition: 46,
  },
  {
    externalUserPermissionReference: 'fafe5865-b997-4546-afa5-f97575150e67',
    name: 'AdministrateTachographData',
    description: 'Administrate Tachograph Data',
    bitPosition: 47,
  },
  {
    externalUserPermissionReference: 'c2fc081f-cf64-4196-a946-e46ed004b544',
    name: 'ViewVehicleDataInDriverApp',
    description: 'ViewVehicleDataInDriverApp',
    bitPosition: 48,
  },
  {
    externalUserPermissionReference: 'f5cc6f09-58b2-4045-995e-f1d716ebc084',
    name: 'ViewDrivingTimeInDriverApp',
    description: 'ViewDrivingTimeInDriverApp',
    bitPosition: 49,
  },
  {
    externalUserPermissionReference: '82854c71-3f1e-4c25-a863-af719f47db12',
    name: 'ViewVehiclePositionInDriverApp',
    description: 'ViewVehiclePositionInDriverApp',
    bitPosition: 50,
  },
  {
    externalUserPermissionReference: '899ef884-6ecd-49bd-bd02-e730ef81ff8b',
    name: 'RemoteHeater',
    description: 'RemoteHeater',
    bitPosition: 51,
  },
  {
    externalUserPermissionReference: 'c2c0b7c7-4a13-4a20-9101-dff6cdc21de9',
    name: 'CheckBeforeDrive',
    description: 'CheckBeforeDrive',
    bitPosition: 52,
  },

  {
    externalUserPermissionReference: '0f81f3c5-f9f6-47ac-835d-744c6c0c8e8f',
    name: 'ViewChargingDashboard',
    description: 'ViewChargingDashboard',
    bitPosition: 53,
  },
  {
    externalUserPermissionReference: '96fd8722-3f86-4966-be95-ce60b5dfeeaa',
    name: 'ViewPublicCharging',
    description: 'ViewPublicCharging',
    bitPosition: 54,
  },
  {
    externalUserPermissionReference: '6b1ab1bd-fe59-47b9-9523-b711b247580e',
    name: 'AdministratePublicCharging',
    description: 'AdministratePublicCharging',
    bitPosition: 55,
  },
  {
    externalUserPermissionReference: '5c4ac44f-9c94-4b96-b421-5b46023d62eb',
    name: 'ViewDepartureScheduling',
    description: 'ViewDepartureScheduling',
    bitPosition: 56,
  },
  {
    externalUserPermissionReference: 'e9d0aa6e-85f6-411b-a633-3994d9320b76',
    name: 'AdministrateDepartureScheduling',
    description: 'AdministrateDepartureScheduling',
    bitPosition: 57,
  },
  {
    externalUserPermissionReference: '0134d058-c79e-4a2e-bed7-1d40016cf061',
    name: 'ViewRemoteClimatization',
    description: 'ViewRemoteClimatization',
    bitPosition: 58,
  },
  {
    externalUserPermissionReference: 'ed0ac82d-fe53-49f3-82b8-605f53b82d2b',
    name: 'AdministrateRemoteClimatization',
    description: 'AdministrateRemoteClimatization',
    bitPosition: 59,
  },
  {
    externalUserPermissionReference: '1822a790-64c2-4544-9254-56617e626915',
    name: 'ViewChargingSettings',
    description: 'ViewChargingSettings',
    bitPosition: 60,
  },
  {
    externalUserPermissionReference: '215ff08c-fd33-40d2-b8dd-d4cf5b3218ea',
    name: 'AdministrateChargingSettings',
    description: 'AdministrateChargingSettings',
    bitPosition: 61,
  },
  {
    externalUserPermissionReference: 'b2178a43-1f0a-4516-a3e4-afdf62ed85c4',
    name: 'DeveloperAccess',
    description: 'Developer Access',
    bitPosition: 62,
  },
  {
    externalUserPermissionReference: '9116bf56-ebf9-4674-97cc-24e9d6092dea',
    name: 'SelfAdministrateChargingSettings',
    description: 'Administrate own settings in charging services, e.g. driver app notifications',
    bitPosition: 63,
  },
  {
    externalUserPermissionReference: 'bcebae25-96bb-450e-8310-8061b3a4195e',
    name: 'AllowRemoteEquipmentChargingManagement',
    description: 'Allow remote mangement of charging in vehicles, e.g. handle settings ',
    bitPosition: 64,
  },
  {
    externalUserPermissionReference: '756f3928-c592-4d5d-9143-1cdbfaf37e2e',
    name: 'ViewChargePoints',
    description: 'View charge point information',
    bitPosition: 65,
  },
];
